






























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseTable",
  props: {
    hidePaginator: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    titleClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    remainingSlots(): string[] {
      const reservedSlots = ["actions", "prepend", "title"];
      return Object.keys(this.$scopedSlots).filter((slot) => !reservedSlots.includes(slot));
    },
    hidePaginatorFooter(): boolean {
      return this.hidePaginator;
    },
  },
});
