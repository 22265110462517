








































import { api } from "@/api/api";
import { ApiGetStudyLocationDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { validateNotEmpty, validateMaxLength, validateStudyLocationCode } from "@/shared/helpers/validationHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";

export default defineComponent({
  name: "StudyLocationModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetStudyLocationDto>,
    },
    existingItemId: {
      type: Number,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const studyLocation = ref<ApiGetStudyLocationDto>(getInitialStudyLocationData());
    const isCreateModal = props.modalType === ModalType.Add;
    const isEditModal = props.modalType === ModalType.Edit;

    if (isEditModal) {
      studyLocation.value = deepCloneObject(props.existingItemModal as ApiGetStudyLocationDto);
    }

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        openNotification(store, NotificationItemType.Error, "Mangler informasjon, alle felter er påkrevd");
        return;
      }
      if (isCreateModal) {
        createStudyLocation();
      } else if (isEditModal) {
        updateStudyLocation();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    const createStudyLocation = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.studylocation.createStudyLocationAsync(studyLocation.value);
        openNotification(store, NotificationItemType.Success, "Studiested er opprettet");
        emit("close");
      });
    };

    const updateStudyLocation = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.studylocation.updateStudyLocationAsync(studyLocation.value.id, studyLocation.value);
        openNotification(store, NotificationItemType.Success, "Studiested er lagret");
        emit("close");
      });
    };

    return {
      handleSubmit,
      validateNotEmpty,
      validateMaxLength,
      validateStudyLocationCode,
      studyLocation,
      isEditModal,
    };
  },
});

const getInitialStudyLocationData = (): ApiGetStudyLocationDto => ({
  id: -1,
  code: "",
  name: "",
  isActive: true,
});
