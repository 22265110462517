// TODO use them!

export const validateIsBirthDate = [
  (date: string) =>
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(
      date
    ) || "Fødselsdatoen er ugyldig.",
];
export const validateNotEmpty = [(input: unknown) => !!input || "Feltet er påkrevd"];

export const validateOrganization = [(input: number) => input > 1 || "Feltet er påkrevd"];

export const validateArrayNotEmpty = [
  (input: unknown[]) => !!input || "Feltet er påkrevd",
  (input: unknown[]) => !!input.length || "Feltet er påkrevd",
];

export const validateTimeInput = [
  (input: string) => {
    const [hours, minutes] = input.split(":");
    if (+hours >= 0 && +hours <= 23 && +minutes >= 0 && +minutes <= 59) {
      return +hours >= 0 && +hours <= 23 && +minutes >= 0 && +minutes <= 59;
    }
    return "Ikke gyldig tidsformat tt:mm";
  },
];

export const validateCourseHourInput = ({
  minVal = 1,
  maxVal,
  validateHalfHour = false,
}: {
  minVal?: number;
  maxVal?: number;
  validateHalfHour?: boolean;
}) => {
  let rules: any[] = [];
  if (minVal !== undefined) {
    rules = [...rules, (input: string) => +input >= minVal || `Minste tillatte verdi er ${minVal}`];
  }
  if (maxVal !== undefined) {
    rules = [...rules, (input: string) => +input <= maxVal || `Største tillatte verdi er ${maxVal}`];
  }
  if (validateHalfHour) {
    rules = [
      ...rules,
      (input: string | number) => {
        const decimal = +input % 1;
        if (!decimal || decimal === 0.5) {
          return true;
        }
        return "Må rundes av til nærmeste halvtime";
      },
    ];
  }
  return rules;
};

export const validateIsEmail = [(v: string) => /.+@.+\..+/.test(v) || "E-posten er ikke gyldig."];

export const validateBoolean = [(v: any) => /(?:true|false)/.test(v) || "Feltet er krevd."];

export const validateNonNegativeNumber = [(v: string) => +v >= 0 || "Tallet må være 0 eller høyere."];

export const validateNegativeNumber = [(v: string) => +v <= 0 || "Tallet må være 0 eller lavere."];
export const validateIsNorwegianPhoneNumber = [
  (v: any) => /^((0047)?|(\+47)?|(47)?)\d{8}$/.test(v) || "Telefonnummeret er ikke gyldig",
];

export const validateIsOrganizationNumber = [
  (v: any) => /^([0-9]{4}:)?([0-9]{9})$/.test(v) || "Organisasjonsnummeret er ikke gyldig",
];

// Client account number is optional. Has to be 4 numbers ranging from 0000 to 9999.
export const validateClientAccountNumber = [
  (v: any) => !v || (!isNaN(v) && v.length === 4) || "Klientkontonummeret er ikke gyldig.",
];

export const validateIsNorwegianZipCode = [(v: any) => /^\d{4}$/.test(v) || "Post nr er ikke gyldig"];

export const required = (input: unknown) => !!input || "Feltet er påkrevd";

export const validateMaxLength =
  (maxLength = 500, fieldName = "Feltet") =>
  (v?: string) =>
    (v ?? "").length <= maxLength || `${fieldName} kan ha maks ${maxLength} tegn`;

export const validateStudyLocationCode = (v: string) =>
  /^\d{3}$/.test(v) || "Studiestedsnummer må være numerisk og ha 3 siffer";

export const validateNusCode = (v: string) => /^\d{6}$/.test(v) || "NUS-kode må være numerisk og ha 6 siffer";

export const validateKasId = [
  (input: string) => /^\d{6}$/.test(input) || "KAS-ID må være nummerisk og ha 6 siffer",
  (input: string) => (+input > 300000 && +input < 500000) || "KAS-ID må være mer enn 300000 og mindre enn 500000",
];

export const validateMinimumValue = (minVal = 0) => [
  (input: number) => input >= minVal || `Minste tillatte verdi er ${minVal}`,
];

export const validateMaximumValue = (maxVal = 100) => [
  (input: number) => input <= maxVal || `Minste tillatte verdi er ${maxVal}`,
];
