
























































import { api } from "@/api/api";
import { ApiGetStudyLocationDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import StudyLocationModal from "@/components/administration/studylocation-register/StudyLocationModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";

export default defineComponent({
  name: "StudyLocationRegisterPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    BaseLayout,
    BaseModal,
    StudyLocationModal,
  },
  setup() {
    const search = ref<string>("");
    const modalData = ref(getInitialModalData());
    const studyLocations = ref<ApiGetStudyLocationDto[]>();

    const loadStudyLocations = async () => {
      studyLocations.value = (await api.studylocation.getStudyLocationsPerOrganizationAsync()).data;
    };

    const openCreateStudyLocation = useOpenModal(ModalType.Add, "studiested", modalData);
    const openEditStudyLocation = useOpenModal(ModalType.Edit, "studiested", modalData);

    const closeModal = async (modalData: ModalBaseData) => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        modalData.showModal = false;
        resetModalData();
        await loadStudyLocations();
      });
    };

    const resetModalData = () => {
      modalData.value = getInitialModalData();
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await loadStudyLocations();
      });
    });

    return {
      headers,
      search,
      modalData,
      closeModal,
      openCreateStudyLocation,
      openEditStudyLocation,
      studyLocations,
      canUpsert: computed(() => authService.hasRoles(AuthRole.ImpersonateDepartment)),
    };
  },
});

const headers = [
  { text: "Studiestedsnummer", value: "code" },
  { text: "Studiestedsnavn", value: "name" },
  { text: "Status", value: "isActive" },
  { text: "", value: "actions", sortable: false },
];
