






















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseTooltipIconButton",
  props: {
    color: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleClick(event: MouseEvent) {
      this.$emit("handleClick", event);
    },
  },
});
