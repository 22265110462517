















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseLayout",
  props: {
    displayTabs: {
      type: Boolean,
      default: false,
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
  },
});
