







import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "BaseModal",
  props: {
    maxWidth: {
      type: Number,
      required: false,
      default: 800,
    },
  },
  setup(props) {
    const maxWidthFormatted = ref(`${props.maxWidth ?? 800}px`);
    return { maxWidthFormatted };
  },
});
